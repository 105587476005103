import { useMemo, useCallback } from 'react';
import type { SDKResponse } from '@commercetools/frontend-sdk';
import type { UserExistCheckType } from '@wilm/shared-backend/commerce-commercetools/interfaces/AccountExtended';
import { LoginStates } from '@wilm/shared-backend/commerce-commercetools/interfaces/AccountExtended';
import type { Account, Address } from '@wilm/shared-types/account';
import type { ReCaptchaErrors } from '@wilm/shared-types/google/reCaptcha';
import type { OktaChangePassword } from '@wilm/shared-types/okta/Account';
import { AccountStatus } from '@wilm/shared-types/account/Account';
import useSWR, { mutate } from 'swr';
import { useFormat } from 'helpers/hooks/useFormat';
import { useBrandSettingsContext } from 'providers/brand-settings';
import { sdk } from 'sdk';
import type { GetAccountResult, RegisterAccount, UpdateAccount, UseAccountReturn, RequestPasswordReset, LoginErrors } from './types';
import { useSession } from 'providers/session';
import cookie from 'js-cookie';

const useAccount = (): UseAccountReturn => {
    const extensions = sdk.composableCommerce;
    const sessionSettings = useSession();
    const { wishlist } = useBrandSettingsContext();
    const result = useSWR('/action/account/getAccount', extensions.account.getAccount, {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: true,
        revalidateOnMount: true
    });
    const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });
    const data = useMemo(() => {
        if (result.data?.isError) return { loggedIn: false, accountLoading: false, error: result.error };

        const account = (result.data?.data as GetAccountResult)?.account;

        if (account?.accountId) return { account, loggedIn: true, accountLoading: false };

        return {
            loggedIn: false,
            account: undefined,
            accountLoading: result.isValidating,
            error: result.error
        };
    }, [result]);

    const accountBlocked = useMemo(() => {
        if (!data.account) {
            return false;
        }
        return data.account.statusOverride === AccountStatus.BLOCKED;
    }, [data.account]);

    const shippingAddresses = useMemo(() => {
        if (!data.account) return [];

        return (data.account.addresses ?? []).filter(address => address.isShippingAddress);
    }, [data.account]);

    const billingAddresses = useMemo(() => {
        if (!data.account) return [];

        return (data.account.addresses ?? []).filter(address => address.isBillingAddress);
    }, [data.account]);

    const defaultShippingAddress = useMemo(() => {
        return data.account?.addresses?.find(address => address.isDefaultShippingAddress);
    }, [data.account]);

    const defaultBillingAddress = useMemo(() => {
        return data.account?.addresses?.find(address => address.isDefaultBillingAddress);
    }, [data.account]);

    const checkUserExist = async (
        email: string,
        reCaptchaToken?: string | null
    ): Promise<{ source: LoginStates; accountData: UserExistCheckType['accountData'] | null } | ReCaptchaErrors> => {
        const payload = {
            email,
            reCaptchaToken
        };

        const res = await sdk.callAction<UserExistCheckType>({ actionName: 'account/checkUserExistSource', payload: payload });

        if (res.isError) {
            if (res.error.message === 'Error: reCaptcha.validation.failed') {
                return { reCaptchaToken: { message: 'reCaptcha.validation.failed', showError: true } } as ReCaptchaErrors;
            }

            return { source: LoginStates.NONE, accountData: null };
        } else {
            let source = LoginStates.NONE;
            if (res.data.showPassword) {
                source = LoginStates.SHOW_PASSWORD;
            } else if (res.data.showActivationModal) {
                source = LoginStates.ACTIVATION_MODAL;
            } else if (res.data.trimmedForm) {
                source = LoginStates.TRIMMED;
            }

            return { source, accountData: res.data.accountData };
        }
    };

    const login = async (
        email: string,
        password: string,
        remember?: boolean,
        reCaptchaToken?: string | null
    ): Promise<Account | LoginErrors> => {
        const extensions = sdk.composableCommerce;

        const payload = {
            email,
            password,
            remember,
            reCaptchaToken
        };

        const res = await extensions.account.login(payload);

        await mutate('/action/account/getAccount');
        await mutate('/action/cart/getCart');
        if (wishlist?.enabled) {
            await mutate('/action/wishlist/getWishlist');
        }

        if (res.isError) {
            switch (res.error.message) {
                case 'Error: reCaptcha.validation.failed':
                    return { reCaptchaToken: { message: 'reCaptcha.validation.failed', showError: true } } as LoginErrors;

                case 'Error: Error: Authentication failed':
                    return {
                        loginError: {
                            message: formatErrorMessage({ id: 'auth.wrong', defaultMessage: 'Your Email address or password is invalid' })
                        }
                    } as LoginErrors;

                case 'Error: passwordExpired':
                    return {
                        loginError: {
                            message: formatErrorMessage({
                                id: 'error.password.expired',
                                defaultMessage: 'Your password has expired. Please use “Forgot password” option.'
                            })
                        }
                    } as LoginErrors;

                default:
                    return {
                        loginError: {
                            message: formatErrorMessage({ id: 'wentWrong', defaultMessage: 'Sorry. Something went wrong..' })
                        }
                    } as LoginErrors;
            }
        } else {
            return res.data;
        }
    };

    const logout = useCallback(async () => {
        const result = await sdk.callAction<{
            umbracoSession: string;
        }>({ actionName: 'account/logout' });

        if (!result.isError) {
            if (!sessionSettings?.sessionCookieName || !sessionSettings?.sessionCookieDomain) {
                throw new Error('Session settings are not set!');
            }

            cookie.set(sessionSettings.sessionCookieName, result.data?.umbracoSession, {
                domain: sessionSettings.sessionCookieDomain
            });
        }

        await mutate('session/setUmbracoSessionInSessionData');
        await mutate('/action/account/getAccount');
        await mutate('/action/cart/getCart');
        if (wishlist?.enabled) {
            await mutate('/action/wishlist/getWishlist');
        }
    }, [wishlist?.enabled, sessionSettings]);

    const register = useCallback(
        async (account: RegisterAccount): Promise<Account> => {
            const res = await sdk.callAction({ actionName: 'account/register', payload: account });

            await mutate('/action/account/getAccount');
            await mutate('/action/cart/getCart');
            if (wishlist?.enabled) {
                await mutate('/action/wishlist/getWishlist');
            }

            return res.isError ? ({} as Account) : (res.data as Account);
        },
        [wishlist?.enabled]
    );

    const getOktaAccountByEmail = useCallback(async (oktaLogin: string): Promise<Account> => {
        const payload = {
            oktaLogin
        };

        const res: SDKResponse<Account> = await sdk.callAction({ actionName: 'account/getOktaAccount', payload });
        return res.isError ? ({} as Account) : res.data;
    }, []);

    const getFieldsToDisplay = useCallback(async (email: string): Promise<Account> => {
        const payload = {
            email
        };

        const res: SDKResponse<Account> = await sdk.callAction({ actionName: 'account/getFieldsToDisplay', payload });
        return res.isError ? ({} as Account) : res.data;
    }, []);

    const changePassword = useCallback(
        async (oldPassword: string, newPassword: string, confirmPassword: string): Promise<OktaChangePassword> => {
            const payload = {
                oldPassword,
                newPassword,
                confirmPassword
            };

            const res: SDKResponse<OktaChangePassword> = await sdk.callAction({ actionName: 'account/changePassword', payload });
            return res.isError ? ({} as OktaChangePassword) : res.data;
        },
        []
    );

    const requestPasswordReset = useCallback(async (email: string): Promise<RequestPasswordReset> => {
        const payload = {
            email
        };

        const res: SDKResponse<RequestPasswordReset> = await sdk.callAction({ actionName: 'account/requestReset', payload });
        return res.isError ? ({} as RequestPasswordReset) : res.data;
    }, []);

    const update = useCallback(async (account: UpdateAccount): Promise<Account> => {
        const extensions = sdk.composableCommerce;

        const res = await extensions.account.updateAccount(account);

        await mutate('/action/account/getAccount');

        return res.isError ? ({} as Account) : res.data;
    }, []);

    const addAddress = useCallback(async (address: Omit<Address, 'addressId'>): Promise<Account> => {
        const extensions = sdk.composableCommerce;

        const res = await extensions.account.addAddress(address);

        await mutate('/action/account/getAccount');

        return res.isError ? ({} as Account) : res.data;
    }, []);

    const addShippingAddress = useCallback(async (address: Omit<Address, 'addressId'>): Promise<Account> => {
        const extensions = sdk.composableCommerce;

        const response = await extensions.account.getAccount();

        if (response.isError || !response.data.loggedIn) return {} as Account;

        const res = await sdk.callAction<Account>({
            actionName: 'account/addShippingAddress',
            payload: { account: response.data.account, address }
        });

        await mutate('/action/account/getAccount');

        return res.isError ? ({} as Account) : res.data;
    }, []);

    const addBillingAddress = useCallback(async (address: Omit<Address, 'addressId'>): Promise<Account> => {
        const extensions = sdk.composableCommerce;

        const response = await extensions.account.getAccount();

        if (response.isError || !response.data.loggedIn) return {} as Account;

        const res = await sdk.callAction<Account>({
            actionName: 'account/addBillingAddress',
            payload: { account: response.data.account, address }
        });

        await mutate('/action/account/getAccount');

        return res.isError ? ({} as Account) : res.data;
    }, []);

    const updateAddress = useCallback(async (address: Address): Promise<Account> => {
        const extensions = sdk.composableCommerce;

        const res = await extensions.account.updateAddress(address);

        await mutate('/action/account/getAccount');

        return res.isError ? ({} as Account) : res.data;
    }, []);

    const removeAddress = useCallback(async (addressId: string): Promise<Account> => {
        const extensions = sdk.composableCommerce;

        const res = await extensions.account.removeAddress({ addressId });

        await mutate('/action/account/getAccount');

        return res.isError ? ({} as Account) : res.data;
    }, []);

    const setDefaultBillingAddress = useCallback(async (addressId: string): Promise<Account> => {
        const extensions = sdk.composableCommerce;

        const res = await extensions.account.setDefaultBillingAddress({ addressId });

        await mutate('/action/account/getAccount');

        return res.isError ? ({} as Account) : res.data;
    }, []);

    const setDefaultShippingAddress = useCallback(async (addressId: string): Promise<Account> => {
        const extensions = sdk.composableCommerce;

        const res = await extensions.account.setDefaultShippingAddress({ addressId });

        await mutate('/action/account/getAccount');

        return res.isError ? ({} as Account) : res.data;
    }, []);

    const mutateAccount = useCallback(async () => {
        await mutate('/action/account/getAccount');
    }, []);

    return {
        ...data,
        shippingAddresses,
        billingAddresses,
        defaultShippingAddress,
        defaultBillingAddress,
        checkUserExist,
        login,
        logout,
        register,
        changePassword,
        requestPasswordReset,
        update,
        addAddress,
        addBillingAddress,
        addShippingAddress,
        updateAddress,
        removeAddress,
        setDefaultBillingAddress,
        setDefaultShippingAddress,
        mutateAccount,
        getOktaAccountByEmail,
        getFieldsToDisplay,
        accountBlocked
    };
};

export default useAccount;
