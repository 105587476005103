'use client';

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import MoneyInput from '@commercetools-uikit/money-input';
import type { TMoneyValue } from '@commercetools-uikit/money-input';
import type { LineItem } from '@wilm/shared-types/cart/LineItem';
import debounce from 'lodash.debounce';
import Price from 'components/sales-link/organisms/content/products/price';
import { useSalesLinkCartContext } from 'providers/sales-link/cart';

interface Props {
    lineItem: LineItem;
}

type TCustomEvent = {
    target: {
        id?: string;
        name?: string;
        value?: string | string[] | null;
    };
    persist?: () => void;
};

const LineItemPrice: React.FC<Props> = ({ lineItem }) => {
    const { changeLineItemPrice } = useSalesLinkCartContext();
    const [moneyValue, setMoneyValue] = useState(MoneyInput.parseMoneyValue(lineItem.price as TMoneyValue, 'en'));

    const priceFuncRef = useRef<() => void>();

    const handlePriceChange = (event: TCustomEvent) => {
        if (event.target.name?.endsWith('.amount')) {
            // restrict input to two decimal places
            const value = typeof event.target.value === 'object' ? event.target.value?.join('') : event.target.value;
            console.log('---> value', value);

            if (!value) {
                return;
            }

            // regex to allow only numbers and commas and if dot restrict to two symbol decimals
            const regex = /^[0-9,]+(\.\d{0,2})?$/;
            if (!regex.test(value)) {
                return;
            }

            setMoneyValue(oldValue => {
                console.log('---> oldValue', oldValue);

                return { ...oldValue, amount: value };
            });

        }
    };

    const onPriceChange = useCallback(() => {
        const centAmount = MoneyInput.convertToMoneyValue(moneyValue, 'en')?.centAmount;

        void changeLineItemPrice(lineItem.lineItemId, {
            centAmount,
            currencyCode: lineItem.price?.currencyCode
        });
    }, [changeLineItemPrice, lineItem.lineItemId, lineItem.price, moneyValue.amount]);

    useEffect(() => {
        priceFuncRef.current = onPriceChange;
    }, [onPriceChange]);

    const debouncedPriceChangeCallback = useMemo(() => {
        const func = () => {
            priceFuncRef.current?.();
        };

        return debounce(func, 200);
    }, []);

    return (
        <>
            <p className="mb-6 text-md">
                <span className="pr-6">Price per piece:</span>
                <span className="font-bold">
                    <Price price={lineItem.price!} />
                </span>
            </p>
            {/* change price input */}
            <MoneyInput 
                name="price"
                value={moneyValue}
                onChange={handlePriceChange}
                currencies={[]}
                onBlur={(e) => {
                    debouncedPriceChangeCallback();
                    e.persist?.();
                }}
            />
        </>
    );
};

export default LineItemPrice;
